import React, { Component, useState } from "react";
import {
  Col,
  Row,
  Table,
  Modal,
  Collapse,
  Button,
  CardBody,
  Card,
} from "reactstrap";
import { baseUrl, createSubscription, token } from "../Apis";
import "./plans.scss";
import { loadStripe } from "@stripe/stripe-js";
import { useStripe, useElements, CardElement } from "@stripe/react-stripe-js";
import CardInput from "./CardInput";
import axios from "axios";
import { withRouter } from "react-router-dom";
import bgImage from "../../views/images/new-login-cover.jpeg";
import i18n from "i18n-js";
import { priceKey } from "../../utils/levels";

function SelectPlan(props) {
  const [selectedPlan, setselectedPlan] = useState(3);
  const stripe = useStripe();
  const elements = useElements();

  const redirectToSubscribe = (planId, selectedId, name, price) => {
    setselectedPlan(selectedId);
    localStorage.setItem(
      "apackage",
      JSON.stringify({ name: name, price: price })
    );
    props.history.push("/subscribe/" + planId);
  };

  const redirectToSubscribeWithoutPrice = (planId, selectedId, name) => {
    setselectedPlan(selectedId);
    localStorage.setItem("apackage", JSON.stringify({ name: name }));
    props.history.push("/subscribe/" + planId);
  };

  React.useEffect(() => {
    var token = localStorage.getItem("token");
    var email = localStorage.getItem("email");
    if (token) {
      props.history.push("/profil");
    } else if (!email) {
      props.history.push("/login");
    }
  }, []);

  return (
    <div
      className="animated fadeIn plans"
      style={{ backgroundImage: `url(${bgImage})` }}
    >
      <Row className="pt-4 justify-content-center "></Row>

      <Row className="mt-4 justify-content-center newPlan">
        <Col md={3}>
          <div
            onClick={() => {
              setselectedPlan(1);
            }}
            className={selectedPlan === 1 ? "planCardSelected" : "planCard"}
          >
            {selectedPlan === 1 ? <div className="yourPackage"></div> : null}
            <h4
              className="text-center packageHeading"
              style={{ fontFamily: "NoirPro-SemiBold" }}
            >
              {" "}
              Basic Mate{" "}
            </h4>

            <Row className="mt-3">
              <Col md={1}>
                <i className="fa fa-check"></i>
              </Col>
              <Col md={10}>
                <label className="mb-0">{i18n.t("clubProfile")}</label>
                <p className="text-muted ml-2">{i18n.t("clubProfileDesc")}</p>
              </Col>
            </Row>
            <Row className="mt-3">
              <Col md={1}>
                <i className="fa fa-check"></i>
              </Col>
              <Col md={10}>
                <label className="mb-0">{i18n.t("createHostGame")}</label>
                <p className="text-muted ml-2">
                  {i18n.t("createHostGameDesc")}
                </p>
              </Col>
            </Row>
            <Row className="mt-3">
              <Col md={1}>
                <i className="fa fa-check"></i>
              </Col>
              <Col md={10}>
                <label className="mb-0">
                  {i18n.t("createHostTournaments")}
                </label>
                <p className="text-muted ml-2">
                  {i18n.t("createHostTournamentsDec")}
                </p>
              </Col>
            </Row>

            <Row className="endDiv">
              <Col md={12} className="text-center">
                {priceKey ? (
                  <button
                    onClick={() => {
                      redirectToSubscribeWithoutPrice(
                        "price_1KGB1bGOoC7dnIOcuQNLJ0ch",
                        1,
                        "Basic"
                      );
                    }}
                    className="btn planBtn"
                  >
                    {i18n.t("subscribe")}
                  </button>
                ) : (
                  <>
                    <label className="mb-1">
                      {" "}
                      <strong
                        style={{
                          "font-family": "NoirPro-SemiBold",
                          fontSize: 20,
                        }}
                      >
                        {" "}
                        495 SEK / {i18n.t("month")}{" "}
                      </strong>
                    </label>
                    <p
                      className="text-muted"
                      style={{
                        fontFamily: "NoirPro-Medium",
                        fontSize: 12,
                        color: "grey",
                      }}
                    >
                      {i18n.t("moneydescr")}
                    </p>
                    <p
                      className="mb-3 text-muted"
                      style={{
                        fontFamily: "NoirPro-Medium",
                        fontSize: 12,
                        color: "grey",
                      }}
                    >
                      {i18n.t("moneydescr1")}
                    </p>
                    <button
                      onClick={() => {
                        redirectToSubscribe(
                          "price_1KGB1bGOoC7dnIOcuQNLJ0ch",
                          1,
                          "Basic",
                          495
                        );
                      }}
                      className="btn planBtn"
                    >
                      {i18n.t("subscribe")}
                    </button>
                  </>
                )}
                {/* <p>Try 2 weeks free, after that SEK 129 / month</p>
                                <br /> */}
              </Col>
            </Row>
          </div>
        </Col>
        <Col md={3}>
          {/* <div className="stickTab"><lable>Campaign until january 10</lable> </div> */}
          <div
            onClick={() => {
              setselectedPlan(2);
            }}
            className={selectedPlan === 2 ? "planCardSelected" : "planCard"}
          >
            {selectedPlan === 2 ? <div className="yourPackage"></div> : null}
            <h4
              className="text-center packageHeading"
              style={{ fontFamily: "NoirPro-SemiBold" }}
            >
              {" "}
              Medium Mate{" "}
            </h4>

            <Row className="" style={{ marginTop: 5 }}>
              <Col md={1}>
                <i className="fa fa-check"></i>
              </Col>
              <Col md={10}>
                <label className="mb-0">{i18n.t("basic")}</label>
                <p className="text-muted ml-2">{i18n.t("allIncludeBasic")}</p>
              </Col>
            </Row>
            <Row className="" style={{ marginTop: 5 }}>
              <Col md={1}>
                <i className="fa fa-check"></i>
              </Col>
              <Col md={10}>
                <label className="mb-0">{i18n.t("clubStatistics")}</label>
                <p className="text-muted ml-2">{i18n.t("clubStatisticDesc")}</p>
              </Col>
            </Row>
            <Row className="" style={{ marginTop: 5 }}>
              <Col md={1}>
                <i className="fa fa-check"></i>
              </Col>
              <Col md={10}>
                <label className="mb-0" style={{ textTransform: "capitalize" }}>
                  {i18n.t("playerStatistics")}
                </label>
                <p className="text-muted ml-2">
                  {i18n.t("playerStatiticsDesc")}
                </p>
              </Col>
            </Row>

            <Row className="" style={{ marginTop: 15 }}>
              <Col md={1}>
                <i className="fa fa-check"></i>
              </Col>
              <Col md={10}>
                <label style={{ marginBottom: 0 }}>
                  {i18n.t("createtrainingdes")}
                </label>
                <p className="text-muted ml-2">{i18n.t("trainingdes")}</p>
              </Col>
            </Row>

            <Row className="endDiv">
              <Col md={12} className="text-center">
                {priceKey ? (
                  <button
                    onClick={() => {
                      redirectToSubscribeWithoutPrice(
                        "price_1KGB24GOoC7dnIOcj3nQVleL",
                        2,
                        "Medium"
                      );
                    }}
                    className="btn planBtn"
                  >
                    {i18n.t("subscribe")}
                  </button>
                ) : (
                  <>
                    <label className="mb-1">
                      {" "}
                      <strong
                        style={{
                          "font-family": "NoirPro-SemiBold",
                          fontSize: 20,
                        }}
                      >
                        {" "}
                        795 SEK / {i18n.t("month")}{" "}
                      </strong>
                    </label>
                    <p
                      className="text-muted"
                      style={{
                        fontFamily: "NoirPro-Medium",
                        fontSize: 12,
                        color: "grey",
                      }}
                    >
                      {i18n.t("moneydescr")}
                    </p>
                    <p
                      className="mb-3 text-muted"
                      style={{
                        fontFamily: "NoirPro-Medium",
                        fontSize: 12,
                        color: "grey",
                      }}
                    >
                      {i18n.t("moneydescr1")}
                    </p>
                    <button
                      onClick={() => {
                        redirectToSubscribe(
                          "price_1KGB24GOoC7dnIOcj3nQVleL",
                          2,
                          "Medium",
                          795
                        );
                      }}
                      className="btn planBtn"
                    >
                      {i18n.t("subscribe")}
                    </button>
                  </>
                )}

                {/* <p>First 3 month free. After that SEK 329 / month</p>
                                <p>The offer can only be used once</p>
                                <br /> */}
              </Col>
            </Row>
          </div>
        </Col>
        <Col md={3}>
          <div
            onClick={() => {
              setselectedPlan(3);
            }}
            className={selectedPlan === 3 ? "planCardSelected" : "planCard"}
          >
            {selectedPlan === 3 ? <div className="yourPackage"></div> : null}
            <h4
              className="text-center packageHeading"
              style={{ fontFamily: "NoirPro-SemiBold" }}
            >
              {" "}
              Premium Mate{" "}
            </h4>

            <Row className="mt-3">
              <Col md={1}>
                <i className="fa fa-check"></i>
              </Col>
              <Col md={10}>
                <label className="mb-0">Basic + Medium</label>
                <p className="text-muted ml-2">
                  {i18n.t("allIncludedBasicMedium")}
                </p>
              </Col>
            </Row>
            <Row className="mt-4">
              <Col md={1}>
                <i className="fa fa-check"></i>
              </Col>
              <Col md={10}>
                <label className="mb-0">{i18n.t("communication")}</label>
                <p className="text-muted ml-2">{i18n.t("communicationDesc")}</p>
              </Col>
            </Row>
            <Row className="mt-4">
              <Col md={1}>
                <i className="fa fa-check"></i>
              </Col>
              <Col md={10}>
                <label className="mb-0">{"Reward"}</label>
                <p className="text-muted ml-2">{i18n.t("rewarddes")}</p>
              </Col>
            </Row>
            {/* <Row className="mt-3">
                            <Col md={1}>
                                <i className="fa fa-check"></i>
                            </Col>
                            <Col md={10}>
                                <label style={{marginBottom:0}}>{i18n.t('createtrainingdes')}</label>
                                <p className="text-muted ml-2">{i18n.t('trainingdes')}</p>
                            </Col>
                        </Row> */}

            <Row className="endDiv">
              <Col md={12} className="text-center">
                {priceKey ? (
                  <button
                    onClick={() => {
                      redirectToSubscribeWithoutPrice(
                        "price_1KGB2UGOoC7dnIOcEsWoIjuL",
                        3,
                        "Premium"
                      );
                    }}
                    className="btn planBtn"
                  >
                    {i18n.t("subscribe")}
                  </button>
                ) : (
                  <>
                    <h4
                      style={{
                        color: "#FF5400",
                        fontSize: 15,
                        fontFamily: "NoirPro-Medium",
                      }}
                    >
                      {i18n.t("valuepack")}
                    </h4>
                    <label className="mb-1">
                      <strong
                        style={{
                          "font-family": "NoirPro-SemiBold",
                          fontSize: 20,
                        }}
                      >
                        995 SEK / {i18n.t("month")}
                      </strong>
                    </label>
                    <p
                      className="text-muted"
                      style={{
                        fontFamily: "NoirPro-Medium",
                        fontSize: 12,
                        color: "grey",
                      }}
                    >
                      {i18n.t("moneydescr")}
                    </p>
                    <p
                      className="mb-3 text-muted"
                      style={{
                        fontFamily: "NoirPro-Medium",
                        fontSize: 12,
                        color: "grey",
                      }}
                    >
                      {i18n.t("moneydescr1")}
                    </p>
                    <p
                      className="mb-3 text-muted"
                      style={{
                        fontFamily: "NoirPro-Medium",
                        fontSize: 12,
                        color: "grey",
                      }}
                    >
                      {i18n.t("moneydescr2")}
                    </p>
                    <button
                      onClick={() => {
                        redirectToSubscribe(
                          "price_1KGB2UGOoC7dnIOcEsWoIjuL",
                          3,
                          "Premium",
                          995
                        );
                      }}
                      className="btn planBtn"
                    >
                      {i18n.t("subscribe")}
                    </button>
                  </>
                )}
                {/* <p>Try 2 weeks free, after that SEK 129 / month</p> */}
                {/* <br /> */}
              </Col>
            </Row>
          </div>
        </Col>
      </Row>
      {/* <Card > */}
      {/* <div >
       
        <CardInput />
        <div >
        <Button variant="contained" color="primary"  onClick={handleSubmitSub}>
            Subscription
          </Button>
        </div>
      </div> */}
      {/* </Card> */}
    </div>
  );
}

export default withRouter(SelectPlan);
