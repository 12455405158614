import React, { useState } from "react";
import ThemeContext from "./ThemeContext";
import Colors from "../../utils/colors_constant";

export default function ThemeGlobalContext(props) {
  const [themeColors, setThemeColor] = useState({


    blackColor: Colors.Black_Color,
    whiteColor: Colors.White_Color,
    lightOpo: Colors.lightOpo,
    darkOpo: Colors.darkOpo,

    primaryColor: Colors.Dark_Primary_Color,
    secondryColor: Colors.Dark_Secondary_Color,
    cardColor: Colors.Dark_Card_Color,
    labelColor: Colors.White_Color,
    subCardColor: Colors.Sub_Dark_Card_Color,
    mutedColor: Colors.Muted_Dark_Color,
    darkBlueColor: Colors.Dark_Blue_Color,
    buttonBackGroundColor: Colors.Dark_Button_BackGround_Color,
  });

  return (
    <ThemeContext.Provider
      value={{
        themeColors,
        setThemeColor,
      }}
    >
      {props.children}
    </ThemeContext.Provider>
  );
}
