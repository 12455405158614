class Colors {
    static Normal_Primary_Color = "#fff";
    static Normal_Secondary_Color = "#000";
    static Dark_Primary_Color = "#000B29";
    static Dark_Secondary_Color = "#fff";
    static Black_Color = "#000";
    static White_Color = "#fff";
    static Normal_Card_Color = "#fff";
    static Dark_Card_Color = "#1A2340";
    static Sub_Dark_Card_Color = "#5b647f";
    static light_Gray = "#fafafa";
    static Muted_Light_Color = "#666b78";
    static Muted_Dark_Color = "#ffffffc9";
    static Dark_Blue_Color = "#000b29";
    static Dark_Button_BackGround_Color = "#000b29";
    static Normal_Button_BackGround_Color = "transparent";
    static darkOpo = "#000a29";
    static lightOpo = "#efefef";

  }
  export default Colors;  