import * as firebase from "firebase/app";
import "firebase/messaging";
import { updateUser } from "./views/Apis";
let initializedFirebaseApp=firebase
if(!firebase.apps.length)
{
   initializedFirebaseApp = firebase.initializeApp({
    apiKey: "AIzaSyCTllK1OKm-YRcEeKXEc2KNcPtmZFZrqIk",
    authDomain: "padel-mates-e1167.firebaseapp.com",
    databaseURL: "https://padel-mates-e1167.firebaseio.com",
    projectId: "padel-mates-e1167",
    storageBucket: "padel-mates-e1167.appspot.com",
    messagingSenderId: "839575022441",
    appId: "1:839575022441:web:cd1a27956908374d9b6bf8",
    measurementId: "G-PR4XT5MWMW"
  });
}
else{
  let initializedFirebaseApp = firebase
}

let messaging;
  if ('Notification' in window) {
    try{
      messaging = initializedFirebaseApp.messaging();
    } 
    catch{
      
    }
  // Initialize Firebaseconst


} else {
  messaging=false
   console.log(' not supported'); 
  }


  export const askForPermissioToReceiveNotifications = async () => {
    if(messaging!=false)
    {
      try {
  
  
        console.log()
        await messaging.requestPermission();
        const token = await messaging.getToken({ vapidKey: 'BGrWK9E7kkF0DrHQZ8vn53P2ByKHbwuU6-7EPy_4e-PAsnTy9y1qvhCy9_IzHWQwMrTj7o9-sdfnc6Of8QfL7R4' });
        console.log('token', token)
        updateUser(localStorage.getItem('token'),{'fcmToken':token})
    
        return token;
      } catch (error) {
        console.error(error);
      }
    }
    else{
      return 'token'
    }

  
  }

  export const onMessageListener = () =>
  new Promise((resolve) => {
    messaging.onMessage((payload) => {
      resolve(payload);
    });
  });

export { messaging };
