export const getImage = (im) => {
  if (im == 1) {
    return require("../views/images/1.png").default;
  } else if (im == 2) {
    return require("../views/images/2.png").default;
  } else if (im == 3) {
    return require("../views/images/3.png").default;
  } else if (im == 4) {
    return require("../views/images/4.png").default;
  } else if (im == 5) {
    return require("../views/images/5.png").default;
  } else if (im == 6) {
    return require("../views/images/6.png").default;
  } else if (im == 7) {
    return require("../views/images/7.png").default;
  } else if (im == 8) {
    return require("../views/images/8.png").default;
  } else if (im == 9) {
    return require("../views/images/9.png").default;
  } else if (im == 10) {
    return require("../views/images/10.png").default;
  } else if (im == "1_5") {
    return require("../views/images/1_5.png").default;
  } else if (im == "2_5") {
    return require("../views/images/2_5.png").default;
  } else if (im == "3_5") {
    return require("../views/images/3_5.png").default;
  } else if (im == "4_5") {
    return require("../views/images/4_5.png").default;
  } else if (im == "5_5") {
    return require("../views/images/5_5.png").default;
  } else if (im == "6_5") {
    return require("../views/images/6_5.png").default;
  } else if (im == "7_5") {
    return require("../views/images/7_5.png").default;
  } else if (im == "8_5") {
    return require("../views/images/8_5.png").default;
  } else if (im == "9_5") {
    return require("../views/images/9_5.png").default;
  }
};