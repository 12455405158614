import { getImage } from './getImage';

export const levels = [
  {
    name: 'name1',
    img: getImage(1),
    im: 1,
    des: "des1",
    levelName: "Level 1",
    realPoints: 1
  },
  {
    name: 'name1',
    img: getImage('1_5'),
    im: '1_5',
    des: "des1",
    levelName: "Level 1.5",
    realPoints: 1.5
  },

  {
    name: 'name2',
    img: getImage(2),
    im: 2,
    des: "des2",
    levelName: "Level 2",
    realPoints: 2
  },

  {
    name: 'name2',
    img: getImage('2_5'),
    im: '2_5',
    des: "des2",
    levelName: "Level 2.5",
    realPoints: 2.5
  },
  {
    name: 'name3',
    img: getImage(3),
    im: 3,
    des: "des3",
    levelName: "Level 3",
    realPoints: 3
  },
  {
    name: 'name3',
    img: getImage('3_5'),
    im: '3_5',
    des: "des3",
    levelName: "Level 3.5", realPoints: 3.5
  },
  {
    name: 'name4',
    img: getImage(4),
    im: 4,
    des: "des4",
    levelName: "Level 4",
    realPoints: 4
  },
  {
    name: 'name4',
    img: getImage('4_5'),
    im: '4_5',
    des: "des4",
    levelName: "Level 4.5",
    realPoints: 4.5
  },

  {
    name: 'name5',
    img: getImage(5),
    im: 5,
    des: "des5",
    levelName: "Level 5",
    realPoints: 5
  },
  {
    name: 'name5',
    img: getImage('5_5'),
    im: '5_5',
    des: "des5",
    levelName: "Level 5.5",
    realPoints: 5.5
  },
  {
    name: 'name6',
    img: getImage(6),
    im: 6,
    des: "des6",
    levelName: "Level 6",
    realPoints: 6
  },
  {
    name: 'name6',
    img: getImage('6_5'),
    im: '6_5',
    des: "des6",
    levelName: "Level 6.5",
    realPoints: 6.5
  },
  {
    name: 'name7',
    img: getImage(7),
    im: 7,
    des: "des7",
    levelName: "Level 7",
    realPoints: 7
  },
  {
    name: 'name7',
    img: getImage('7_5'),
    im: '7_5',
    des: "des7",
    levelName: "Level 7.5",
    realPoints: 7.5
  },
  {
    name: 'name8',
    img: getImage(8),
    im: 8,
    des: "des8",
    levelName: "Level 8",
    realPoints: 8
  },
  {
    name: 'name8',
    img: getImage('8_5'),
    im: '8_5',
    des: "des8",
    levelName: "Level 8.5",
    realPoints: 8.5
  },
  {
    name: 'name9',
    img: getImage(9),
    im: 9,
    des: "des9",
    levelName: "Level 9",
    realPoints: 9
  },
  {
    name: 'name9',
    img: getImage('9_5'),
    im: '9_5',
    des: "des9",
    levelName: "Level 9.5",
    realPoints: 9.5
  },
  {
    name: 'name10',
    img: getImage(10),
    im: 10,
    des: "des10",
    levelName: "Level 10"
  },
];

export const priceKey = false;
