import "react-app-polyfill/ie9"; // For IE 9-11 support
import "react-app-polyfill/ie11"; // For IE 11 support
import "./polyfill";
import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css";
import * as serviceWorker from "./serviceWorker";
// import { registerServiceWorker } from "./register-sw";
import { registerServiceWorker } from "./serviceWorker";

if ("Notification" in window) {
  registerServiceWorker();
} else {
  console.log(" not supported");
}
ReactDOM.render(<App />, document.getElementById("root"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
