import React, { Component } from 'react';
import { HashRouter, Route, Switch } from 'react-router-dom';
// import { renderRoutes } from 'react-router-config';
import Loadable from 'react-loadable';
import './App.scss';
import firebase from 'firebase'
import { ToastsContainer, ToastsStore } from 'react-toasts';
import Plans from './views/Plans/Plans';
// import './padel-mates-e1167-firebase-adminsdk-voo5m-9cc2a274cc'
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import Subscribe from './views/Plans/Subscribe';
import SelectPlan from './views/Plans/SelectPlan';
import i18n from 'i18n-js';
import en from './string/en.json';
import sw from './string/sw.json';
import dan from './string/dan.json';
import duc from './string/duc.json';
import eta from './string/eta.json';
import frn from './string/frn.json';
import ger from './string/ger.json';
import spa from './string/spa.json';
import nor from './string/nor.json';
import 'firebase/storage';
import { askForPermissioToReceiveNotifications, onMessageListener } from './init-fcm';
import ThemeGlobalContext from './views/Context/GlobalThemeContext';
const stripePromise = loadStripe('pk_test_51IVCKMGOoC7dnIOcaYMO74hORu5RV8fppU78d6HppX3P4zzCD5qGiV7Y0CTaAlahtunHLezbOzwUXHiI9q1Y7dQC00bIWpozYE');
var admin = require("firebase-admin");

var serviceAccount = require("./padel-mates-e1167-firebase-adminsdk-voo5m-9cc2a274cc.json");
// console.log = function () { }
document.onkeydown = function (e) {
  if (e.keyCode == 123) {
    return false;
  }
  if (e.ctrlKey && e.shiftKey && e.keyCode == 'I'.charCodeAt(0)) {
    return false;
  }
  if (e.ctrlKey && e.shiftKey && e.keyCode == 'C'.charCodeAt(0)) {
    return false;
  }
  if (e.ctrlKey && e.shiftKey && e.keyCode == 'J'.charCodeAt(0)) {
    return false;
  }
  if (e.ctrlKey && e.keyCode == 'U'.charCodeAt(0)) {
    return false;
  }
}
// Initialize Firebase
// admin.initializeApp(firebaseConfig);
// console.log = function() {}
export const storageRef = firebase.storage().ref();
const loading = () => <div className="animated fadeIn pt-3 text-center">Loading...</div>;

// Containers
const DefaultLayout = Loadable({
  loader: () => import('./containers/DefaultLayout'),
  loading
});

// Pages
const Login = Loadable({
  loader: () => import('./views/Pages/Login'),
  loading
});

const CreateAccount = Loadable({
  loader: () => import('./views/Pages/CreateAccount'),
  loading
});

const Register = Loadable({
  loader: () => import('./views/Pages/Register'),
  loading
});

const Page404 = Loadable({
  loader: () => import('./views/Pages/Page404'),
  loading
});

const Page500 = Loadable({
  loader: () => import('./views/Pages/Page500'),
  loading
});

const LanguageSelect = Loadable({
  loader: () => import('./views/Pages/LanguageSelect/LanguageSelect.js'),
  loading
});

class App extends Component {


  componentDidMount() {
    askForPermissioToReceiveNotifications()
    i18n.fallbacks = true;
    i18n.translations = {
      en,
      sw,
      dan,
      ger,
      spa,
      eta,
      duc,
      frn,
      nor
    };
    try {
      var value = localStorage.getItem("lang")
      if (value == null) {
        i18n.locale = 'en';
      }
      else {
        i18n.locale = value;
      }
    }
    catch{
      console.log('error')
    }

    onMessageListener()
      .then((payload) => {
        console.log('payload', payload);
      })
      .catch((err) => console.log("failed: ", err));

  }
  render() {

    return (
      <Elements stripe={stripePromise}>
        <HashRouter>
          <ThemeGlobalContext>
            <Switch>
              <Route exact path="/LanguageSelect" name="LanguageSelect" component={LanguageSelect} />
              <Route exact path="/login" name="Login Page" component={Login} />
              <Route exact path="/Plans" name="Plans" component={SelectPlan} />
              <Route exact path="/subscribe/:id" name="Subscribe" component={Subscribe} />
              <Route exact path="/register" name="Register Page" component={Register} />
              <Route exact path="/createAccount" name="Create Account" component={CreateAccount} />
              <Route exact path="/404" name="Page 404" component={Page404} />
              <Route exact path="/500" name="Page 500" component={Page500} />
              <Route path="/" name="Home" component={DefaultLayout} />
            </Switch>
          </ThemeGlobalContext>
        </HashRouter>
      </Elements>
    );
  }
}

export default App;
