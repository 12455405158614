import React, { Component, useState } from "react";
import {
  Col,
  Row,
  Table,
  Modal,
  ModalBody,
  Collapse,
  Button,
  CardBody,
  Card,
  Spinner,
  Label,
  Input,
  FormGroup
} from "reactstrap";
import { baseUrl, createSubscription, token, updateUser, invoiceRequest } from "../Apis";
import "./plans.scss";
import { loadStripe } from '@stripe/stripe-js';
import { useStripe, useElements, CardElement } from '@stripe/react-stripe-js';
import CardInput from './CardInput';
import axios from 'axios';
import "./subscribe.scss";
import { ToastsStore, ToastsContainer } from "react-toasts";
import i18n from 'i18n-js';
import { priceKey } from "../../utils/levels";

function Subscribe(props) {
  const [loading, setLoading] = React.useState(false);
  const [packagea, setPackage] = React.useState();
  const [collapse1, setcollapse1] = useState(false);
  const [collapse2, setcollapse2] = useState(false);
  const [showConfirm1, setshowConfirm1] = useState(false);
  const [companyName, setcompanyName] = useState("");
  const [companyEmail, setcompanyEmail] = useState("");

  const stripe = useStripe();
  const elements = useElements();


  React.useEffect(() => {
    var apackage = localStorage.getItem('apackage')
    if (apackage) {
      setPackage(JSON.parse(apackage))
    }


    var email = localStorage.getItem('email')
    if (!email) {
      props.history.push('/CreateAccount')
    }
  }, []);

  const handleInvoiceSub = () => {
    setLoading(true)
    var email = localStorage.getItem('email')
    invoiceRequest(email , companyName , companyEmail)
    .then((res) => {
      // debugger;
      setcompanyName('');
      setcompanyEmail('');
      setLoading(false)
      ToastsStore.success("Thank you! We will call you soon.");
    })
    .catch((err) => {
      ToastsStore.error("Error");
      setLoading(false)
    })
    // var planDetails = localStorage.getItem('apackage');
    // var data = JSON.parse(planDetails);
    // console.log(data);
    
    // debugger;
    var subscribeInfo = {
      "customerId":"",
      type:'invoice',
      subscriptionId:"",
      amount:packagea.price*100,
      currency:"sek",
      interval:"month",
      planId:props.match.params.id,
      plan:packagea.name,
      "status" : false,
      "warning":false
    }
    // debugger;
    updateUser(email , {
      "subscriptionInfo" : subscribeInfo,
    })
    props.history.push("/login")
  }
  const handleSubmitSub = async (event) => {
    setLoading(true)
    var email = localStorage.getItem('email')
    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    const result = await stripe.createPaymentMethod({
      type: 'card',
      card: elements.getElement(CardElement),
      billing_details: {
        email: email,
      },
    });

    if (result.error) {
      console.log(result.error.message);
      ToastsStore.error(result.error.message);
      setLoading(false)
    } else {
      const res = await axios.post(baseUrl + '/createSubscription', { 'payment_method': result.paymentMethod.id, 'email': email, priceId: props.match.params.id, plan: packagea.name });
      // eslint-disable-next-line camelcase
      const { client_secret, status } = res.data;

      if (status === 'requires_action') {
        stripe.confirmCardPayment(client_secret).then(function (result) {
          if (result.error) {
            console.log('There was an issue!');
            console.log(result.error);
            ToastsStore.error(result.error);
            setLoading(false)
            // Display error message in your UI.
            // The card was declined (i.e. insufficient funds, card has expired, etc)
          } else {
            console.log(result);
            setLoading(false)
            // Show a success message to your customer
          }
        });
      } else {
        console.log(res);
        if (res.data.status == true) {
          setLoading(false)
          localStorage.setItem('email', email)
          localStorage.setItem('token', email)
          // updateUser(email, { subCustomerId: res.data.customerId, client_secret: res.data.client_secret })
          props.history.push("/profile")
          window.location.reload()
          setLoading(false)


        }
        // No additional information was needed
        // Show a success message to your customer
      }
    }
  };

  return (
    <div className="animated fadeIn plans subscribeSection">
      {packagea && <Row className="justify-content-center">
        <ToastsContainer store={ToastsStore} />

        <Modal isOpen={showConfirm1} contentClassName="notiModal premiummodal" centered >
          <ModalBody>
            {/* <Button className="closeModalBtn"><i className="fa fa-times"></i></Button> */}
            <br />
            <div className="text-center">
            <i className="fa fa-check-circle text-success" style={{fontSize: 50}}></i>
              <div className="d-flex justify-content-center mt-4">
                <h6><strong>{i18n.t('willcontactyou')}</strong></h6>
              </div>
              {/* <label className="mb-4 mt-3">{i18n.t('refresScheduleDes')}</label> */}
              <br />
              {/* <Row>
                <Col>
                  <Button className="newPost outline" style={{
                    background: "#000b29",
                    color: "#fff",
                    border: "none",
                    padding: "10px 45px",
                    fontWeight: "bold",
                    fontSize: 12,
                    width: 170

                  }}

                  >{i18n.t('notnow')}</Button>

                </Col>
              </Row> */}
            </div>

          </ModalBody>

        </Modal>
        <Col md={4}>
          <h3 style={{ textTransform: 'uppercase', color: "#42ed9b", fontWeight: 'bold', fontFamily: 'NoirPro-SemiBold' }}>{packagea.name} {packagea.price} SEK</h3>

          {/* <p style={{ fontSize: 20, fontFamily: "NoirPro-Medium" }}><i>"{i18n.t('paymenttrailend')}"</i></p> */}

          <div className="mt-4">
            {!priceKey &&
            <>
            <div className="p-2 text-dark font-weight-bold" onClick={() => setcollapse1(!collapse1)} style={{ cursor: 'pointer', borderRadius: "5px", marginBottom: '1rem', background: "#42ed9b" }}>{i18n.t('paymentThroughCard')}</div>

            <Collapse isOpen={collapse1}>
              <Card className="cardForDetails">
                <CardBody>
                  <h2 className="text-dark" style={{ fontSize: 13, fontFamily: "NoirPro-Medium", marginBottom: 20 }}>{i18n.t('enterCard')}</h2>

                  <Card className="subscribeCard">
                    <div>
                      <CardInput hidePostalCode={true} />
                      <div>
                        <Button disabled={loading == true} size="lg" variant="contained" color="primary" block={true} onClick={handleSubmitSub} className="subscribeBtn" style={{ fontSize: 14, fontFamily: 'NoirPro-SemiBold', paddingTop: 10, paddingBottom: 10 }}>
                          {i18n.t('done')} {loading ? <Spinner size="sm">
                            Loading...
                  </Spinner> : null}
                        </Button>
                      </div>
                    </div>
                  </Card>
                </CardBody>
              </Card>
            </Collapse>
            </>}
            <div className="p-2 text-dark font-weight-bold" onClick={() => setcollapse2(!collapse2)} style={{ cursor: 'pointer', borderRadius: "5px", marginBottom: '1rem', background: "#42ed9b" }}>{i18n.t('invoice')}</div>
            <Collapse isOpen={collapse2}>
              <Card className="cardForDetails text-left">
                <CardBody>
                  <FormGroup>
                    <Label className="text-dark" for="">{i18n.t('companyName')}:</Label>
                    <Input type="text" name="" id="" value={companyName} placeholder="" onChange={(e) => setcompanyName(e.target.value)} />
                  </FormGroup>
                  <FormGroup>
                    <Label className="text-dark" for="">{i18n.t('companyEmailAddress')}:</Label>
                    <Input type="email" name="" id="" value={companyEmail} placeholder="" onChange={(e) => setcompanyEmail(e.target.value)} />
                  </FormGroup>
                  <div>
                    <Button disabled={loading == true} size="lg" variant="contained" color="primary" block={true} onClick={handleInvoiceSub} className="subscribeBtn" style={{ fontSize: 14, fontFamily: 'NoirPro-SemiBold', paddingTop: 10, paddingBottom: 10 }}>
                      {i18n.t('done')} {loading ? <Spinner size="sm">
                        Loading...
                  </Spinner> : null}
                    </Button>
                  </div>
                </CardBody>
              </Card>
            </Collapse>
          </div>
        </Col>
      </Row>}
    </div>
  );

}

export default Subscribe;